import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IExternalUser } from '@core/model';
import { UsersService } from '@core/services';
import { MessageService } from 'primeng/api';
import { Subject, Subscription } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-profile-widget',
  templateUrl: './user-profile-widget.component.html',
  styleUrls: ['./user-profile-widget.component.scss']
})
export class UserProfileWidgetComponent implements OnInit, OnDestroy {
  private subs: Subscription = new Subscription();

  @Input() userId = '';
  @Input() set accountTypeIdNew(value: number) {
    this.reloadUserData(value);
  }
  @Input() set userAccountInformationChanged(value: number) {
    if (value > 0) {
      this.reloadUserData(value);
    }
  }

  @Output() accountTypeId = new EventEmitter<number>();
  user!: IExternalUser;
  users: IExternalUser[] = [];
  isLoading = false;
  // tslint:disable-next-line: no-any
  initialFormValue!: any;
  isFormChanged = false;
  dateTime!: string;
  form!: FormGroup;

  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private userService: UsersService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.userId = params.uid;
      });
    this.getUserById(this.userId);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.subs.unsubscribe();
  }

  reloadUserData(value: number): void {
    if (value && this.userId) {
      this.getUserById(this.userId);
    }
  }

  onSave(): void {
    const data = { ...this.form.value };
    const externalUser = {
      isApproved: this.user.isActive,
      firstName: data.givenName,
      lastName: data.surname,
      phone: data.telephoneNumber,
      phoneExtension: data.telephoneNumberExtension,
      fax: data.facsimileTelephoneNumber,
      displayName: `${data.givenName} ${data.surname}`
    } as IExternalUser;
    
    this.userService.saveUserById(this.userId, externalUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.messageService.add({
          severity: 'success',
          key: 'saved',
          detail: `All changes were saved successfully`,
          life: 5000
        });
        this.getUserById(this.userId);
      });
  }

  onCancel(): void {
    if (this.form) {
      this.form.get('givenName')?.setValue(this.user.firstName);
      this.form.get('surname')?.setValue(this.user.lastName);
      this.form.get('otherMails')?.setValue(this.user.email);
      this.form.get('telephoneNumber')?.setValue(this.user.phone);
      this.form.get('telephoneNumberExtension')?.setValue(this.user.phoneExtension);
      this.form.get('facsimileTelephoneNumber')?.setValue(this.user.fax);
    }
  }

  getUserById(userId: string): void {
    this.isLoading = true;
    this.subs = this.userService.getUserById(userId)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.isLoading = false)
      )
      .subscribe(user => {
        this.user = user;
        this.dateTime = user.modifiedOn ? user.modifiedOn : user.createdOn;
        this.user.isActive = this.user.isApproved;
        this.users = [user];
        this.accountTypeId.emit(this.user.accountTypeId);
        this.createForm(this.user);
      });
  }

  createForm(user: IExternalUser): void {
    if(user){
      this.form = new FormGroup({
        givenName: new FormControl(user.firstName, Validators.required),
        surname: new FormControl(user.lastName, Validators.required),
        otherMails: new FormControl({ disabled: true, value: user.email }),
        telephoneNumber: new FormControl(user.phone, [
          Validators.minLength(10),
          Validators.required,
          Validators.pattern('[0-9]{10}')
        ]),
        telephoneNumberExtension: new FormControl(user.phoneExtension || '', [
          Validators.maxLength(9),
        ]),
        facsimileTelephoneNumber: new FormControl(user.fax, [
          Validators.minLength(10),
          Validators.required,
          Validators.pattern('[0-9]{10}')
        ]),
        accountEnabled: new FormControl(user.isActive)
      });
      this.initialFormValue = Object.assign({}, this.form.value);
      this.isFormChanged = false;
  
      this.form.valueChanges.subscribe(() => this.handleFormChange());
      }
  }

  handleFormChange(): void {
    this.isFormChanged = this.formChanged();
  }

  formChanged(): boolean {
    return (
      Object.keys(this.initialFormValue).filter(
        key => this.initialFormValue[key] !== this.form.value[key]
      ).length > 0
    );
  }

  goBack(): void {
    this.router.navigate(['users']);
  }

  get canSaveUser(): boolean {
    return !(this.isFormChanged && this.form.valid);
  }
}
